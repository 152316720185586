import React, { useState, useEffect } from 'react';
import { Alert, Table, Typography, Skeleton } from 'antd';
import { APIs, Pages } from '../utils/constants';
import { useIntl } from 'react-intl';
import { useQuery } from '../utils/url-param';
import { getUser } from '../utils/auth';
import { getFileLink } from '../utils/get-file-link';
import { useHistory } from 'react-router-dom';
import Text from '../components/text';
import Helmet from 'react-helmet';
import DashboardFooter from '../components/dashboard-footer';

function SkillMatch() {
    const user = getUser();
    const query = useQuery();
    const history = useHistory();
    const skill = query.get('s') ? query.get('s') : '';

    // States
    const [tableTitle, setTableTitle] = useState('');
    const [tableColumns, setTableColumns] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);
    const [loading, setLoading] = useState(true);

    // Actions
    useEffect(async () => {
        setLoading(true);
        const loadingColumns = [];
        for (let i = 0; i < 4; i++) {
            const loadingColumn = {
                key: i,
                title: <Skeleton.Button active='true' size='small' />,
                dataIndex: 'data'
            };
            loadingColumns.push(loadingColumn);
        }

        const loadingDatas = [];
        for (let i = 0; i < 15; i++) {
            const loadingData = { key: i, data: <Skeleton.Button active='true' size='small' className='w-100' /> };
            loadingDatas.push(loadingData);
        }

        setTableColumns(loadingColumns);
        setTableData(loadingDatas);
        setTableTitle(<Skeleton.Button active='true' />);

        try {
            const response = await fetch(APIs.GET_SKILL_MATCH, {
                method: 'POST', body: JSON.stringify({
                    user: user.id,
                    token: user.deviceIdentity,
                    ctoken: user.ctoken,
                    skill: skill.trim()
                })
            });
            const r = await response.json();
            if (response.ok) {
                let data = [];
                let columns = [];
                columns = r.cols ? r.cols.map((col, index) => {
                    return {
                        key: index,
                        title: <Text id={'match::table::header::' + col.name} />,
                        dataIndex: col.name,
                        render: text => colRender(text, col),
                        width: col.width,
                        align: col.align ? col.align : 'left',
                    }
                }) : [];

                setTableTitle(<Text id={'skill-match::table::title'} values={{
                    name: () => <span className='fw-light'>{skill}</span>
                }} />);

                data = r.items ? r.items.map((item, index) => {
                    return {
                        key: index,
                        name: item.name,
                        match: item.percentage,
                        email: item.email,
                        phone: item.phone,
                    }
                }) : [];

                setTableData([]);
                setTableColumns(columns);
                setTableData(data);
                setErrorMessage(false);
                setLoading(false);
            } else if (response.status == 403) {
                localStorage.removeItem('user');
                history.push(Pages.HOME);
            } else {
                setErrorMessage(<Text id='match::error::fetch' />);
            }
        } catch (error) {
            setErrorMessage(<Text id='match::error::fetch' />);
        }
    }, []);


    const colRender = (text, col) => {
        if (col.isLink) {
            return <a onClick={() => getFileLink(text.fid, history, setErrorMessage)} className='text-primary'>
                {text.value}
            </a>
        } else if (col.isMatch) {
            return {
                props: {
                    className: parseInt(text.value) >= 80 ? 'bg-green' : parseInt(text.value) >= 60 ? 'bg-yellow' : 'bg-red'
                },
                children: <div>{text.value + '%'}</div>
            }
        } else if (col.name == 'email') {
            return <a href={'mailto: ' + text.value}>
                {text.value}
            </a>
        } else if (col.name == 'phone') {
            return <a href={'tel: ' + text.value}>
                {text.value}
            </a>
        } else {
            return text.value;
        }
    }

    return (
        <>
            <Helmet>
                <title>
                    {`${useIntl().formatMessage({ id: 'skill-match::text::title-name' })}`}
                </title>
            </Helmet>
            <div className='row vh-100'>
                <div className='col-12 pt-5 px-4 position-relative'>
                    {errorMessage &&
                        <Alert
                            showIcon={false}
                            type='error'
                            banner
                            className='position-absolute w-100 text-danger top-0 start-0 text-center'
                            message={errorMessage}
                        />
                    }
                    <div className='d-flex'>
                        <Typography.Title level={3} className='m-0'>
                            {tableTitle}
                        </Typography.Title>
                        {!loading && <div className='mt-auto mb-0 ms-5 fs-5 fw-light'>
                            <Typography.Text>
                                <Text id='match::table::sub-title' values={{
                                    number: () => <strong>{tableData.length}</strong>
                                }} />
                            </Typography.Text>
                        </div>}
                    </div>
                    <Table
                        columns={tableColumns}
                        pagination={{ position: ['topRight'], pageSize: 15, showSizeChanger: false }}
                        dataSource={tableData}
                    />
                </div>
                <div className='mt-auto'>
                    <DashboardFooter />
                </div>
            </div>
        </>
    );
}

export default SkillMatch;

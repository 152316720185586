import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Pages } from '../utils/constants';
import { getUser } from '../utils/auth';
import PropTypes from 'prop-types';

GuardedRoute.propTypes = {
    component: PropTypes.func.isRequired,
    match: PropTypes.any,
    isLogin: PropTypes.bool,
    userLoggedIn: PropTypes.func
}

function GuardedRoute({ component: Component, isLogin, userLoggedIn, ...rest }) {
    const user = getUser();
    return <Route {...rest} render={(props) => (
        isLogin ?
            user ?
                <Redirect to={Pages.DASHBOARD} /> :
                <Component userLoggedIn={userLoggedIn} {...props} /> :
            user ?
                <Component {...props} /> :
                <Redirect to={Pages.HOME} />
    )} />
}

export default GuardedRoute;
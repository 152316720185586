import React from 'react';
import { NavLink } from 'react-router-dom';
import { Pages, JobsterLink, ExternalLink } from '../utils/constants';
import Text from './text';
import JobsterWhiteIconImage from '../assets/images/jobster-icon-white.png';
import JobsterWhiteTextImage from '../assets/images/jobster-text-white.png';
import KeLogoImage from '../assets/images/ke-logo.png';

function Footer() {
    return (
        <div className='row bg-blue py-3 py-lg-5 px-4'>
            <div className='col-lg-4 offset-lg-1 p-0'>
                <NavLink to={Pages.HOME}>
                    <img src={JobsterWhiteIconImage} width='30'></img>
                    <img src={JobsterWhiteTextImage} height='30' className='ms-2 mt-1'></img>
                </NavLink>
                <a href={ExternalLink.KE_HOME} target='_blank' rel='noopener noreferrer' className='d-none d-lg-inline'>
                    <img src={KeLogoImage} height='30' className='ms-3'></img>
                </a>
                <div className='mt-3 d-none d-lg-block'>
                    <small><Text id='footer::text::copy-right' /></small>
                </div>
            </div>
            <div className='col-lg-4 d-flex p-0 mt-4 mt-lg-0 fw-light'>
                <div className='col-6 p-0'>
                    <p className='mb-2'>
                        <a href={JobsterLink.ABOUT_US} target='_blank' rel='noopener noreferrer' className='text-white'>
                            <Text id='footer::link::about-jobster' />
                        </a>
                    </p>
                    <p className='mb-2'>
                        <a href={JobsterLink.PRIVACY_POLICY} target='_blank' rel='noopener noreferrer' className='text-white'>
                            <Text id='footer::link::privacy-policy' />
                        </a>
                    </p>
                    <p>
                        <a href={JobsterLink.TERMS_OF_USE} target='_blank' rel='noopener noreferrer' className='text-white'>
                            <Text id='footer::link::terms-of-use' />
                        </a>
                    </p>
                </div>
                <div className='col-6 p-0'>
                    <p className='mb-2'>
                        <a href={ExternalLink.KE_HOME} target='_blank' rel='noopener noreferrer' className='text-white'>
                            <Text id='footer::link::ke-solutions' />
                        </a>
                    </p>
                    <p className='mb-2 '>
                        <a href={ExternalLink.FACEBOOK} target='_blank' rel='noopener noreferrer' className='text-white'>
                            <Text id='footer::link::facebook' />
                        </a>
                    </p>
                    <p>
                        <a href={ExternalLink.LINKEDIN} target='_blank' rel='noopener noreferrer' className='text-white'>
                            <Text id='footer::link::linkedin' />
                        </a>
                    </p>
                </div>
            </div>
            <div className='mt-3 d-block d-lg-none'>
                <small><Text id='footer::text::copy-right' /></small>
            </div>
        </div>
    );
}
export default Footer;

import React from 'react';
import { Modal, Alert } from 'antd';
import PropTypes from 'prop-types';
import Button from '../button.js';
import './modal-with-alert.scss';

ModalWithAlert.propTypes = {
    title: PropTypes.object,
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    className: PropTypes.string,
    footer: PropTypes.array,
    children: PropTypes.object,
    alerts: PropTypes.array,
    alertsOption: PropTypes.object
}

ModalWithAlert.defaultProps = {
    title: '',
    visible: false
};

function ModalWithAlert(props) {
    const alerts = [];
    props.alerts.forEach((alert) => {
        alerts.push(<Alert key={alerts.length} type={alert.type} message={alert.content} description={alert.description} banner closable
            action={
                alert.actionText &&
                <Button solid={false} size='sm' className='px-2 ml-3'
                    onClick={(e) => alert.action(e, props.alerts)} disabled={props.alertsOption?.disableAllActions ? props.alertsOption.disableAllActions : false}>{alert.actionText}
                </Button>
            } />);
    });
    return (
        <Modal
            title={props.title}
            visible={props.visible}
            onCancel={props.onCancel}
            className={`${props.className} modal-with-alert`}
            footer={props.footer}>
            <div className='modal-alert-container'>
                {alerts.length > 0 && alerts}
            </div>
            {props.children}
        </Modal>
    );
}

export default ModalWithAlert;

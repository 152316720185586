import React from 'react';
import Text from './text';

function DashboardFooter() {
    return (
        <div className='bg-light-gray py-2 text-center'>
            <div>
                <Text id='dashboard-footer::content1' />
            </div>
            <div>
                <Text id='dashboard-footer::content2' />
            </div>
        </div>
    );
}
export default DashboardFooter;

import React, { useState, useRef, useEffect } from 'react';
import { Typography, Input, Alert } from 'antd';
import { useIntl } from 'react-intl';
import { CloudUploadOutlined, FileAddOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { APIs, Pages } from '../utils/constants';
import { getUser } from '../utils/auth';
import BackgroundImage from '../assets/images/dashboard-background.png';
import Button from '../components/button';
import Text from '../components/text';
import UploadModal from '../components/modal/upload-modal';
import Footer from '../components/footer';
import Helmet from 'react-helmet';
function Dashboard() {
    const user = getUser();
    const uploadModal = useRef();
    const history = useHistory();
    // State
    const [uploadType, setUploadType] = useState('jobs');
    const [jobCount, setJobCount] = useState(0);
    const [resumeCount, setResumeCount] = useState(0);
    const [error, setError] = useState(false);

    // CSS
    const styles = {
        backgroundImage: {
            backgroundImage: `url(${BackgroundImage})`,
            backgroundSize: '100% 100%'
        }
    }

    // Actions
    useEffect(async () => {
        getListingCount();
    }, []);

    const getListingCount = async () => {
        try {
            const response = await fetch(APIs.GET_LISTING_COUNT, {
                method: 'POST', body: JSON.stringify({
                    user: user.id,
                    token: user.deviceIdentity,
                    ctoken: user.ctoken
                })
            });
            const r = await response.json();
            if (response.ok) {
                setError(false);
                updateCount('job', r.jobs);
                updateCount('resume', r.resumes);
            } else if (response.status == 403) {
                localStorage.removeItem('user');
                history.push(Pages.HOME);
            } else {
                setError(true);
            }
        } catch (error) {
            setError(true);
        }
    }

    const updateCount = (type, end) => {
        let start = type === 'job' ? jobCount : resumeCount;
        if (start === end) return;
        const increment = Math.max(Math.floor((end - start) / 5), 1);

        let timer = setInterval(() => {
            start = Math.min(start + increment, end);
            type === 'job' ? setJobCount(start) : setResumeCount(start);
            if (start === end) clearInterval(timer)
        }, 100);
    }

    const openUploadModal = (type) => {
        setUploadType(type);
        uploadModal.current.showModal();
    }

    return (
        <>
            <Helmet>
                <title>
                    {`${useIntl().formatMessage({ id: 'dashboard::text::title-name' }, { company: user.company })}`}
                </title>
            </Helmet>
            <div className='row text-center mb-5'>
                <div className='col-12 p-0 position-relative'>
                    {error &&
                        <Alert
                            showIcon={false}
                            type='error'
                            banner
                            className='position-absolute w-100 text-danger top-0 start-0 text-center'
                            message='dashboard::error::general'
                        />
                    }
                    {/* <img src={BackgroundImage} className='w-100' style={styles.image} /> */}
                </div>
                <div className='col-12 d-flex p-0 position-relative mt-5 py-5' style={styles.backgroundImage}>
                    <div className='col-4 offset-1 p-0'>
                        <Typography.Title level={3} className='m-0 text-white'>
                            <Text id='dashboard::text::title::job' />
                        </Typography.Title>
                        <Typography.Title className='m-0 fw-light my-4 text-white'>
                            {jobCount.toLocaleString()}
                        </Typography.Title>
                        <Button solid={false} onClick={() => { history.push(Pages.JOBS) }}>
                            <Text id='dashboard::button::view-jobs' />
                        </Button>
                    </div>
                    <div className='col-4 offset-2 p-0'>
                        <Typography.Title level={3} className='m-0 text-blue'>
                            <Text id='dashboard::text::title::resume' />
                        </Typography.Title>
                        <Typography.Title className='m-0 fw-light my-4 text-blue'>
                            {resumeCount.toLocaleString()}
                        </Typography.Title>
                        <Button onClick={() => { history.push(Pages.RESUMES) }}>
                            <Text id='dashboard::button::view-resumes' />
                        </Button>
                    </div>
                </div>
                <div className='col-lg-8 col-10 offset-lg-2 offset-1 my-4'>
                    <Input.Search
                        placeholder={useIntl().formatMessage({ id: 'dashboard::placeholder::search' })}
                        enterButton
                        size='large'
                        onSearch={(skill) => { skill.trim().length && history.push(Pages.SKILL_MATCH + '?s=' + skill) }} />
                </div>
                <div className='col-lg-8 col-10 offset-lg-2 offset-1 d-flex'>
                    <div className='col-6 pe-3'>
                        <div className='bg-light-gray py-5 rounded-15 cursor-pointer shadow'
                            onClick={() => openUploadModal('jobs')}>
                            <CloudUploadOutlined className='large-icon' />
                            <Typography.Title level={4} className='m-0 fw-light mt-4 text-blue'>
                                <Text id='dashboard::button::upload-job' />
                            </Typography.Title>
                        </div>
                    </div>
                    <div className='col-6 ps-3'>
                        <div className='bg-light-gray py-5 rounded-15 cursor-pointer shadow'
                            onClick={() => openUploadModal('resumes')}>
                            <FileAddOutlined className='large-icon' />
                            <Typography.Title level={4} className='m-0 fw-light mt-4 text-blue'>
                                <Text id='dashboard::button::add-resume' />
                            </Typography.Title>
                        </div>
                    </div>
                </div>
                <UploadModal ref={uploadModal} type={uploadType} updateListingCount={getListingCount} />
            </div>
            <Footer />
        </>
    );
}

export default Dashboard;

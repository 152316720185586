import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { getUser } from '../utils/auth';
import { useHistory } from 'react-router-dom';
import { APIs, Pages, PageTypes } from '../utils/constants';
import ViewTable from '../components/view-table';
import Text from '../components/text';
import Helmet from 'react-helmet';

function ViewResumes() {
    const history = useHistory();

    // States
    const [tableData, setTableData] = useState({});
    const [errorMessage, setErrorMessage] = useState(null);
    const [loading, setLoading] = useState(true);

    // Actions
    useEffect(async () => {
        setLoading(true);
        const user = getUser();
        try {
            const response = await fetch(APIs.GET_RESUMES, {
                method: 'POST', body: JSON.stringify({
                    user: user.id,
                    token: user.deviceIdentity,
                    ctoken: user.ctoken
                })
            });
            const r = await response.json();
            if (response.ok) {
                setTableData(r);
                setErrorMessage(null);
                setLoading(false);
            } else if (response.status == 403) {
                localStorage.removeItem('user');
                history.push(Pages.HOME);
            } else {
                setErrorMessage(<Text id='resumes::error::general' />);
            }
        } catch (error) {
            setErrorMessage(<Text id='resumes::error::general' />);
        }
    }, []);

    const handleDelete = async resumeId => {
        const user = getUser();
        try {
            const response = await fetch(APIs.DELETE_RESUME, {
                method: 'POST', body: JSON.stringify({
                    user: user.id,
                    token: user.deviceIdentity,
                    ctoken: user.ctoken,
                    resume: resumeId
                })
            });
            if (response.ok) {
                const newTableData = {...tableData};
                newTableData.count -= 1;
                newTableData.items = newTableData.items.filter(resume => resume.delete.value != resumeId )
                setTableData(newTableData);
            } else if (response.status == 403) {
                localStorage.removeItem('user');
                history.push(Pages.HOME);
            } else {
                setErrorMessage(<Text id='resumes::error::general' />);
            }
        } catch (error) {
            setErrorMessage(<Text id='resumes::error::general' />);
        }
    }

    return (
        <>
            <Helmet>
                <title>
                    {`${useIntl().formatMessage({ id: 'resumes::text::title-name' })}`}
                </title>
            </Helmet>
            <ViewTable
                data={tableData}
                error={errorMessage}
                type={PageTypes.RESUME}
                loading={loading}
                onDelete={handleDelete}
            />
        </>
    );
}

export default ViewResumes;

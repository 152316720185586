import React from 'react';
import { NavLink } from 'react-router-dom';
import { Typography } from 'antd';
import { Pages } from '../utils/constants';
import PropTypes from 'prop-types';
import JobsterBlueIconImage from '../assets/images/jobster-icon-blue.png';
import JobsterBlueTextImage from '../assets/images/jobster-text-blue.png';
import Button from './button';
import Text from './text';

Header.propTypes = {
    user: PropTypes.any,
    userLoggedOut: PropTypes.func.isRequired
}

function Header(props) {
    const user = props.user;

    const logout = () => {
        localStorage.removeItem('user');
        props.userLoggedOut();
    }

    return (
        <div className='row shadow-sm pt-3 pb-lg-2 pb-3 px-3 bg-light-gray'>
            <div className='w-100 d-flex'>
                <div className='col-6 p-0'>
                    <NavLink to={Pages.HOME}>
                        <img src={JobsterBlueIconImage} width='45'></img>
                        <img src={JobsterBlueTextImage} height='40' className='ms-4 mt-2'></img>
                    </NavLink>
                </div>
                <div className='col-4 offset-2 d-flex align-items-center p-0'>
                    <div className='text-end w-100'>
                        {
                            user ?
                                <div className='d-flex text-end align-items-center'>
                                    <Typography.Title level={5} className='m-0 ms-auto me-3'>
                                        {user.name}
                                    </Typography.Title>
                                    <Typography.Text className='text-decoration-underline cursor-pointer' onClick={logout}>
                                        <Text id='header::button::logout' />
                                    </Typography.Text>
                                </div>
                                :
                                <Button>
                                    <Text id='header::button::sign-in' />
                                </Button>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Header;

import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Upload, Typography, Progress } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { CSSTransition } from 'react-transition-group';
import { getUser } from '../../utils/auth';
import { APIs, Pages } from '../../utils/constants';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import docIconImage from '../../assets/images/doc-icon.png';
import docxIconImage from '../../assets/images/docx-icon.png';
import pdfIconImage from '../../assets/images/pdf-icon.png';
import txtIconImage from '../../assets/images/txt-icon.png';
import ModalWithAlert from './modal-with-alert';
import Button from '../button';
import Text from '../text';
import './upload-modal.scss';

const UploadModal = forwardRef((props, ref) => {
    const user = getUser();
    const history = useHistory();

    // States
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [progressPercentage, setProgressPercentage] = useState(0);
    const [modalAlerts, setModalAlerts] = useState([]);
    const [submitDisabled, setSubmitDisabled] = useState(true);

    // CSS
    const styles = {
        uploadFileSection: {
            height: '100%',
            background: '#fafafa',
            border: '1px dashed #d9d9d9',
            borderRadius: '2px'
        },
        progress: {
            bottom: '-7px'
        },
        fileTitle: {
            width: '150px'
        },
        fileStatus: {
            width: '200px'
        }
    }

    // Actions
    const draggerProps = {
        accept: 'application/pdf,.doc,.docx,application/msword,text/plain',
        multiple: true,
        onChange(info) {
            if (info.file.uid === info.fileList[info.fileList.length - 1].uid) {
                info.fileList.splice(Math.min(50, info.fileList.length));
                setFileList(info.fileList);
            }
            setSubmitDisabled(false);
        },
        beforeUpload: () => {
            return false;
        },
    }

    useImperativeHandle(ref, () => ({
        showModal() {
            setIsModalVisible(true);
        }
    }))

    const handleClose = () => {
        setProgressPercentage(0);
        setIsModalVisible(false);
        setSubmitDisabled(true);
        setFileList([]);
        setModalAlerts([]);
    }

    const handleRemove = (index) => {
        const newFileList = [...fileList];
        newFileList.splice(index, 1);
        setFileList(newFileList);
        if (!newFileList.length) {
            setSubmitDisabled(true);
        }
    }

    const handleUploadError = (failedFiles) => {
        if (failedFiles.length) {
            setModalAlerts([
                ...modalAlerts,
                {
                    'type': 'error',
                    'content': <Text id='modal::upload-modal::text::error' values={{
                        files: () => failedFiles.join(', '),
                        bold: msg => <strong>{msg}</strong>
                    }} />
                }
            ]);
        }
    }

    const handleSubmit = async () => {
        setSubmitDisabled(true);
        let currentProgressPercentage = 20;
        setProgressPercentage(currentProgressPercentage);
        const failedFiles = [];
        const stepPercentage = Math.ceil(80 / fileList.length);
        const formData = new FormData();
        formData.append('type', props.type);
        formData.append('user', user.id);
        formData.append('token', user.deviceIdentity);
        formData.append('ctoken', user.ctoken);

        for (let index = 0; index < fileList.length; index++) {
            let file = fileList[index];
            formData.delete('files[]');
            formData.append('files[]', file.originFileObj);
            try {
                const response = await fetch(APIs.UPLOAD_FILE, { method: 'POST', body: formData });
                const r = await response.json();
                if (response.ok) {
                    currentProgressPercentage += stepPercentage;
                    setProgressPercentage(currentProgressPercentage);

                    if (!r[0].success) {
                        failedFiles.push(file.name);
                    }
                } else if (response.status == 403) {
                    localStorage.removeItem('user');
                    history.push(Pages.HOME);
                } else {
                    failedFiles.push(file.name);
                }
                afterSubmit(r, index);
            } catch (error) {
                failedFiles.push(file.name);
            }

            if (index === fileList.length - 1) {
                handleUploadError(failedFiles);
                props.updateListingCount();
            }
        }
    }

    const afterSubmit = (res, index) => {
        const newFileList = [...fileList];
        if (res[0].success) {
            newFileList[index].status = <Typography.Text className='text-success'>
                <Text id='modal::upload-modal::text::success' />
            </Typography.Text>;
        } else {
            newFileList[index].status = <Typography.Text
                className='text-danger'
                style={styles.fileStatus}
                ellipsis={{ tooltip: res[0].error }}>
                {res[0].error}
            </Typography.Text>;
        }
        setFileList(newFileList);
    }

    const getFileIcon = (file) => {
        return supportFileMap[file.name.split('.').pop().toLowerCase()] ?
            supportFileMap[file.name.split('.').pop().toLowerCase()] : ''
    }

    // UI
    const supportFileMap = {
        'doc': <img src={docIconImage} width='40' />,
        'docx': <img src={docxIconImage} width='40' />,
        'pdf': <img src={pdfIconImage} width='40' />,
        'txt': <img src={txtIconImage} width='40' />
    }

    const modalContent = fileList.length ?
        <div className='position-relative h-100'>
            <div className='text-center p-3 overflow-scroll' style={styles.uploadFileSection}>
                {fileList.map((file, index) => <div className='d-flex' key={index}>
                    {getFileIcon(file)}
                    <Typography.Text
                        className='my-auto ms-2 text-start'
                        style={styles.fileTitle}
                        ellipsis={{ tooltip: file.name }}>
                        {file.name}
                    </Typography.Text>
                    <div className='ms-auto my-auto'>
                        {file.status == undefined &&
                            <Typography.Text className='cursor-pointer' onClick={() => handleRemove(index)}>
                                <DeleteOutlined />
                            </Typography.Text>
                        }
                        <CSSTransition
                            in={file.status != undefined}
                            timeout={1000}
                            classNames='fade-in'
                            unmountOnExit>
                            <>
                                {file.status}
                            </>
                        </CSSTransition>

                    </div>
                </div>
                )}
            </div>
            <Progress className='position-absolute' percent={progressPercentage} showInfo={false} style={styles.progress}></Progress>
        </div>
        :
        <Upload.Dragger {...draggerProps}>
            <p className='ant-upload-drag-icon'>
                {/* <img src={cloud} width='55' /> */}
            </p>
            <h5 className='text-gray font-weight-normal'>
                <Text id='modal::upload-modal::text::description' values={{
                    primary: msg => <u className='text-primary'>{msg}</u>
                }} />
            </h5>
            <Text className='fw-light' id='modal::upload-modal::text::max-file' />
        </Upload.Dragger>

    const modalTitle = <Text id='modal::upload-modal::title' values={{
        type: () => props.type.toUpperCase()
    }} />

    return (
        <ModalWithAlert
            title={modalTitle}
            visible={isModalVisible}
            onCancel={handleClose}
            className='upload-modal'
            alerts={modalAlerts}
            footer={[
                <Button key='submit' onClick={handleSubmit} disabled={submitDisabled}>
                    <Text id='modal::upload-modal::button::submit' />
                </Button>
            ]}>
            <>
                {modalContent}
                <div className='text-gray mt-1'>
                    <div className='d-inline-block'>
                        <Text id='modal::upload-modal::text::support-file' />
                    </div>
                </div>
            </>
        </ModalWithAlert>
    );
})

UploadModal.propTypes = {
    type: PropTypes.string.isRequired,
    updateListingCount: PropTypes.func
}

UploadModal.defaultProps = {
    updateListingCount: () => {}
};

export default UploadModal;

import React from 'react';
import PropTypes from 'prop-types';
Button.propTypes = {
    children: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    solid: PropTypes.bool,
    onClick: PropTypes.func,
    size: PropTypes.string,
    className: PropTypes.string
}

Button.defaultProps = {
    disabled: false,
    solid: true,
    size: 'md'
};

function Button(props) {
    const classNames = require('classnames');
    const btnClass = classNames({
        'jobster-btn': true,
        'jobster-btn-blue': !props.disabled && props.solid,
        'jobster-btn-white': !props.disabled && !props.solid,
        'jobster-btn-sm': props.size === 'sm',
        'jobster-btn-md': props.size === 'md',
        'jobster-btn-lg': props.size === 'lg'
    }, props.className);
    return (
        <button
            onClick={props.onClick}
            disabled={props.disabled}
            className={btnClass}>{props.children}</button >
    );
}

export default Button;

import React, { useState } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { Pages } from './utils/constants';
import { getUser } from './utils/auth';
import Login from './pages/login';
import GuardedRoute from './components/guarded-route.js';
import Header from './components/header';
import Dashboard from './pages/dashboard';
import ViewJobs from './pages/jobs';
import ViewResumes from './pages/resumes';
import Match from './pages/match';
import SkillMatch from './pages/skill-match';
import './App.scss';

function App() {
    // States
    const [user, setUser] = useState(getUser());

    // Actions
    const userUpdate = () => {
        setUser(getUser());
    }

    return (
        <div className='container-fluid d-flex flex-column vh-100'>
            <Router>
                <Header user={user} userLoggedOut={userUpdate} />
                <Switch>
                    <GuardedRoute path={Pages.DASHBOARD} component={Dashboard} />
                    <GuardedRoute path={Pages.JOBS} component={ViewJobs} />
                    <GuardedRoute path={Pages.RESUMES} component={ViewResumes} />
                    <GuardedRoute path={Pages.MATCH} component={Match} />
                    <GuardedRoute path={Pages.SKILL_MATCH} component={SkillMatch} />
                    <GuardedRoute path={Pages.HOME} component={Login} isLogin={true} userLoggedIn={userUpdate} />
                </Switch>
            </Router>
        </div>
    );
}

export default App;

import React, { useState, useEffect } from 'react';
import { Typography, Table, Alert, Skeleton, Input } from 'antd';
import { useHistory } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import { Pages, PageTypes } from '../utils/constants';
import { getFileLink } from '../utils/get-file-link';
import { Trash } from 'react-bootstrap-icons';
import './view-table.scss';
import Text from './text';
import Button from './button';
import PropTypes from 'prop-types';
import DashboardFooter from './dashboard-footer.js';
import moment from 'moment';

ViewTable.propTypes = {
    type: PropTypes.oneOf([PageTypes.JOB, PageTypes.RESUME]).isRequired,
    data: PropTypes.object.isRequired,
    error: PropTypes.object,
    loading: PropTypes.bool,
    onDelete: PropTypes.func
}

ViewTable.defaultProps = {
    data: { cols: [], items: [] },
    loading: true
};

function ViewTable(props) {
    // States
    const [tableColumns, setTableColumns] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [tabletitle, settabletitle] = useState('');
    const [tableDataSize, setTableDataSize] = useState(0);
    const [errorMessage, setErrorMessage] = useState(props.error);

    const history = useHistory();
    const alphabetSort = (col) => {
        return (a, b) => a[col].value.localeCompare(b[col].value)
    }
    const searchContainer = (col) => (prop) => (
        <div className='ant-table-filter-dropdown'>
            <div className='p-2'>
                <Input
                    placeholder={useIntl().formatMessage({ id: 'view-table::table::tool::search::'+col })}
                    value={prop.selectedKeys.length ? prop.selectedKeys[0] : ''}
                    onChange={(e) =>
                        prop.setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => prop.confirm()}
                />
            </div>
            <div className='ant-table-filter-dropdown-btns p-2'>
                <button
                    disabled={!prop.selectedKeys.length || !prop.selectedKeys[0].length}
                    className='ant-btn ant-btn-link ant-btn-sm'
                    onClick={() => {
                        prop.clearFilters();
                    }}>
                    <Text id='view-table::table::tool::reset' />
                </button>
                <Button
                    size='sm'
                    onClick={() => prop.confirm()}
                    className='px-2'>
                    <Text id='view-table::table::tool::search' />
                </Button>
            </div>
        </div>
    );
    const columnProps = {
        'uploaded': {
            filters: [
                {
                    text: <Text id='view-table::table::tool::filter::all-time' />,
                    value: 'all',
                },
                {
                    text: <Text id='view-table::table::tool::filter::past-30' />,
                    value: 'past30',
                },
                {
                    text: <Text id='view-table::table::tool::filter::past-60' />,
                    value: 'past60',
                },
                {
                    text: <Text id='view-table::table::tool::filter::past-90' />,
                    value: 'past90',
                }
            ],
            onFilter: (value, record) => {
                if (value == 'past30') {
                    return !moment(record.uploaded.value).isBefore(moment().subtract(30, 'days'));
                } else if (value == 'past60') {
                    return !moment(record.uploaded.value).isBefore(moment().subtract(60, 'days')) && moment(record.uploaded.value).isBefore(moment().subtract(30, 'days'));
                } else if (value == 'past90') {
                    return !moment(record.uploaded.value).isBefore(moment().subtract(90, 'days')) && moment(record.uploaded.value).isBefore(moment().subtract(60, 'days'));
                } else {
                    return true;
                }
            },
            sorter: alphabetSort('uploaded')
        },
        'name': {
            filterDropdown: searchContainer('name'),
            filterIcon: (filtered) => (
                <SearchOutlined style={{ color: filtered ? '$jobster-blue' : undefined }} />
            ),
            onFilter: (value, record) => {
                return record.name.value
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase());
            },
            sorter: alphabetSort('name')
        },
        'position': {
            filterDropdown: searchContainer('position'),
            filterIcon: (filtered) => (
                <SearchOutlined style={{ color: filtered ? '$jobster-blue' : undefined }} />
            ),
            onFilter: (value, record) => {
                return record.position.value
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase());
            },
            sorter: alphabetSort('position')
        }
    }

    // Actions
    useEffect(async () => {
        const loadingColumns = [];
        const colNum = props.type === PageTypes.JOB ? 3 : 5;
        for (let i = 0; i < colNum; i++) {
            const loadingColumn = {
                key: i,
                title: <Skeleton.Button active='true' size='small' />,
                dataIndex: 'data'
            };
            loadingColumns.push(loadingColumn);
        }

        const loadingDatas = [];
        for (let i = 0; i < 15; i++) {
            const loadingData = { key: i, data: <Skeleton.Button active='true' size='small' className='w-100' /> };
            loadingDatas.push(loadingData);
        }

        const columns = props.data.cols ? props.data.cols.map(col => {
            return {
                title: <Text id={'view-table::table::header::' + col.name} />,
                dataIndex: col.name,
                ellipsis: col.ellipsis,
                render: text => colRender(text, col),
                width: col.width,
                ...columnProps[col.name]
            }
        }) : [];

        if (props.loading) {
            settabletitle(<Skeleton.Button active='true' />);
            setTableColumns(loadingColumns);
            setTableData(loadingDatas);
        } else {
            settabletitle(<Text id='view-table::text::title' values={{
                type: () => props.type.charAt(0).toUpperCase() + props.type.slice(1),
                number: () => props.data.items.length
            }} />);
            setTableColumns(columns);
            setTableData(props.data.items);
            setTableDataSize(props.data.items.length);
        }
    }, [props.loading, props.data]);

    const colRender = (text, col) => {
        if (col.isLink) {
            return <a className='text-primary' onClick={() => getFileLink(text.fid, history, setErrorMessage)}>
                {text.value}
            </a>
        } else if (col.isButton) {
            return <Button size='sm' onClick={() => history.push(Pages.MATCH.replace(':id', text.value))}>
                <Text id={'view-table::table::header::' + col.name} />
            </Button>
        } else if (col.isDelete) {
            return <Trash className='cursor-pointer' onClick={() => props.onDelete(text.value)} />
        } else {
            return text.value;
        }
    }

    useEffect(() => {
        settabletitle(<Text id='view-table::text::title' values={{
            type: () => props.type.charAt(0).toUpperCase() + props.type.slice(1),
            number: () => tableDataSize
        }} />);
    }, [tableDataSize]);

    return (
        <div className='row vh-100'>
            <div className='col-12 p-0 d-flex position-relative flex-column'>
                {(props.error || errorMessage) &&
                    <Alert
                        showIcon={false}
                        type='error'
                        banner
                        className='position-absolute w-100 text-danger top-0 start-0 text-center'
                        message={props.error || errorMessage}
                    />
                }
                <div className='p-5 pb-0'>
                    <Typography.Title level={3} className='m-0'>
                        {tabletitle}
                    </Typography.Title>
                    <Table
                        columns={tableColumns}
                        pagination={{ position: ['topRight'], pageSize: 15, showSizeChanger: false, total: tableDataSize ? tableDataSize : 1 }}
                        dataSource={tableData}
                        onChange={(...args) => setTableDataSize(args[3].currentDataSource.length)}
                    />
                </div>
                <div className='mt-auto'>
                    <DashboardFooter />
                </div>
            </div>
        </div>
    );
}
export default ViewTable;

import React, { useState } from 'react';
import { Alert, Form, Input, Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import { APIs } from '../utils/constants';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import JobsterBlueIconImage from '../assets/images/jobster-icon-blue.png';
import Text from '../components/text';
import Button from '../components/button';

Login.propTypes = {
    userLoggedIn: PropTypes.func.isRequired
}

function Login(props) {
    // States
    const [loginErrorMessage, setLoginErrorMessage] = useState(null);

    const history = useHistory();

    const onFinish = async (values) => {
        try {
            const response = await fetch(APIs.LOGIN, {
                method: 'POST', body: JSON.stringify({
                    email: values.email.trim(),
                    password: values.password.trim()
                })
            });
            const r = await response.json();
            if (response.ok) {
                localStorage.setItem('user', JSON.stringify(r.user));
                props.userLoggedIn();
                history.push(`/${r.user.company}/dashboard`);
            } else {
                setLoginErrorMessage(r.error);
            }
        } catch (error) {
            setLoginErrorMessage(<Text id='global::api::fail' />);
        }
    }

    return (
        <>
            <Helmet>
                <title>
                    {`${useIntl().formatMessage({ id: 'login::text::title-name' })}`}
                </title>
            </Helmet>
            <div className='row text-center py-4'>
                <div className='col-12 my-5 p-0'>
                    <div className='col-4 offset-4'>
                        <img src={JobsterBlueIconImage} width='100' />
                    </div>
                    <div className='col-6 offset-3 mt-4'>
                        <Typography.Title level={2} className='m-0'>
                            <Text id='login::text::title' />
                        </Typography.Title>
                    </div>
                    {
                        loginErrorMessage &&
                        <Alert
                            showIcon={false}
                            type='error'
                            banner
                            className='position-absolute w-100 text-danger'
                            message={loginErrorMessage}
                        />
                    }
                </div>
                <div className='col-8 col-md-6 col-lg-4 offset-2 offset-md-3 offset-lg-4 text-start'>
                    <Form layout='vertical' onFinish={onFinish}>
                        <Form.Item
                            name='email'
                            label={<Text id='login::input::email' />}
                            rules={[
                                { type: 'email', message: <Text id='login::input::email::invalid' /> },
                                { required: true, message: <Text id='login::input::email::required' /> }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            name='password'
                            label={<Text id='login::input::password' />}
                            rules={[{ required: true, message: <Text id='login::input::password::required' /> }]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item className='text-center mb-2'>
                            <Button>
                                <Text id='login::button::sign-in' />
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </>
    );
}

export default Login;
